// extracted by mini-css-extract-plugin
export var profolio_container = "index-module--profolio_container--1yRd0";
export var profolio_left = "index-module--profolio_left--2Syre";
export var profolio_left_content = "index-module--profolio_left_content--2lJHE";
export var profolio_right = "index-module--profolio_right--2NWMG";
export var profolio_right_content = "index-module--profolio_right_content--2BeMZ";
export var head_image_cropper = "index-module--head_image_cropper--l-uox";
export var outter_links_container = "index-module--outter_links_container--19qkh";
export var outter_links_div = "index-module--outter_links_div--ipQfr";
export var section_div = "index-module--section_div--2gkkm";
export var name_div = "index-module--name_div--3YSBD";
export var fordesktop = "index-module--fordesktop--sVOHS";
export var formobile = "index-module--formobile--tBASA";
export var blog_li = "index-module--blog_li--2SIpW";